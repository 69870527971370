import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { strings, translate } from "./../../services/Localization";
import { toast } from "../../utils/Toaster";

import { checkLogin } from "./../../services/Login";

import { useParams, useNavigate, NavigateFunction  } from 'react-router-dom';

import AppContext from '../../context/AppContext'

import { dialog, dialogDescription } from '../../components/Common';
import { appBaseUrl, getAppBase, getLabelBasedOnServerType, UUIDCOLWIDTH } from './../../utils/consts';
import { getVisibleActions, actionIcon, prioritizedTableColumns } from "./../../utils/filters";

import {TAB_ADD_CONNECTIVITY_PROFILE
        ,TAB_ADD_DEVICE_PROFILE
        ,TAB_ADD_SERVICE_PROFILE
        ,TAB_ADD_ROAMING_PROFILE
        ,TAB_ADD_QOS_PROFILE
        ,TAB_ADD_CHANNEL_PROFILE
        //,TAB_ADD_LINKED_PROFILE
        ,TAB_PROFILE_EDIT_ACTION
        ,TAB_CREATE_LINKED_PROFILE_ACTION
        ,TAB_IMPORT_DEVICE_PROFILE
        ,TAB_IMPORT_CONNECTION_PROFILE
        ,TAB_ADD_CONNECTION_PROFILE
        ,TAB_ADD_CONNECTION,
        TAB_ADD_RELAY_PROFILE
        //,TAB_VIEW_PROFILE
    } from '../../datatypes/tabsconstants';

import { faRefresh, faAdd, faFileExport } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt, faLinkSlash } from '@fortawesome/pro-regular-svg-icons';

import { ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import { IUser } from "src/dassTypes";
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, ColumnType, DataTableOption, BulkActionType } from "src/components/Common/DataTable/DataTypes";
import { GenericDassQuery } from "../../../src/services/BasicDassQueries";

import PageContent from "../PageContent";
import { IConstants } from "src/types";
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import { emptyProfileData, profileData } from "../../components/Common/DataTable/DataTableState";
import { isMobile } from "react-device-detect";

declare const constants: IConstants;


export interface IRowType {
    profile_uuid: string;
    profile_name: string;
    profile_image_url: string;
    shared_with_all_users: boolean;
    is_shared: boolean;
    can_be_inspected: boolean;
    link_to_profile_uuid: string;
    id?:string;
    export_image_base64_json?: object; 
    profileContent?: {
        interfaceType?: "HTTP" | "MQTT" | "TCP" | "TR069" | "EXTMQTT";
        deviceType?: "custom" | "tr069" | "orbiwan";
    };
    connection_profile_uuid?: string;
    description?: string;
    eventHandlerDiagnostics?: any
}

interface breadCrumbArrType {
    key: string;
    pathArray: BreadCrumbType[];
};

interface IDeviceProfileState {
    loggedUser: IUser | null;
    ProfileUUID: string | undefined;
    breadCrumbArr: BreadCrumbType[];
    countLabel:string;
    refresh:boolean;
    isRowDeleted?:boolean;
}

type ProfileType =  "device" | "connectivity" | "qos" | "roaming" | "service" | "channel" | "connection" | "connectionInstance" | "relay";

 interface ProfilesPropsType {
    profileType: ProfileType;
 }

const Profiles: React.FC<ProfilesPropsType> = ( props ) => {


    //let can_list_profile: string;
    let can_inspect_profile: string;
    let can_create_profile: string;
    let can_link_profile: string;
    let profileType = props.profileType;
    let profilePath = profileType + "-profiles"

    const AppContextObj = useContext(AppContext);

    const profileLabels = {
        "connectionInstance": {
            "column_label": strings.TABLE_CONNECTION_NAME,
            "success_delete_messsage": strings.CONNECTION_MSG_DELETE_SUCCESS,
            "success_export_message": strings.CONNECTION_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.CONNECTION_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_CONNECTION_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_CONNECTION_MESSAGE_MODAL_BODY
        },
        "device": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "connectivity": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "qos": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "roaming": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "service": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "channel": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "relay": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        },
        "connection": {
            "column_label": strings.TABLE_PROFILE_NAME,
            "success_delete_messsage": strings.PROFILE_MSG_DELETE_SUCCESS,
            "success_export_message": strings.PROFILE_MSG_EXPORT_SUCCESS,
            "success_unlink_message": strings.PROFILE_MSG_UNLINKED_SUCCESS,
            "confirm_dialog_delete_title": strings.DELETE_PROFILE_MODAL_TITLE,
            "confirm_dialog_delete_body" : strings.DELETE_PROFILE_MESSAGE_MODAL_BODY
        }
    }

    //can_list_profile    = "can_list_" + profileType + "_profile";
    can_inspect_profile = profileType === "connectionInstance" ? "can_inspect_connection"  : ( profileType === "connection" ? "can_inspect_connection" : "can_inspect_" + profileType + "_profile");
    can_create_profile  = "can_create_" + profileType + "_profile";
    can_link_profile    = "can_link_" + profileType + "_profile";

    if (profileType === "connectionInstance") {
        can_create_profile  = "can_create_connection";
        profilePath = "connections";
    }



    let  breadCrumpOptions: breadCrumbArrType[] =  [
        {
            key: '/profiles/device',
            pathArray: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_PROFILES_ITEM, ''), {label: strings.NAV_DEVICE_PROFILES, url: ''}],
        },
        {
            key: '/profiles/service',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_SERVICE_PROFILES, url: ''}],
        },
        {
            key: '/profiles/connectivity',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_CONNECTIVITY_PROFILES, url: ''}],
        },
        {
            key: '/profiles/roaming',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_ROAMING_PROFILES, url: ''}],
        },
        {
            key: '/profiles/qos',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_QOS_PROFILES, url: ''}],
        },
        {
            key: '/profiles/channel',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_CHANNEL_PROFILES, url: ''}],
        },
        {
            key: '/profiles/relay',
            pathArray: [{label:strings.NAV_PROFILES_ITEM,url:''}, {label: strings.NAV_RELAY_PROFILES, url: ''}],
        },
        {
            key: '/profiles/connection',
            pathArray: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_PROFILES_ITEM, ''), {label: strings.NAV_CONNECTION_PROFILES, url: ''}],
        },
        {
            key: '/connections',
            pathArray: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, strings.NAV_PROFILES_ITEM, ''), {label: strings.NAV_CONNECTIONS, url: ''}],
        }
    ]

    const path = window.location.pathname;

    function checkPath(item: breadCrumbArrType) {
        return path.indexOf(item.key) > -1;
    }

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const singleBreadCrump: breadCrumbArrType = breadCrumpOptions.find(checkPath);


    const stateInit = {
        loggedUser: AppContextObj.user,
        ProfileUUID: undefined,
        breadCrumbArr: singleBreadCrump.pathArray,
        countLabel: 'Profiles',
        refresh:false,
        isRowDeleted:false
    };
    const [state, setDeviceState] = useState<IDeviceProfileState>(stateInit);
    const isInitialMount = React.useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }else {
            setDeviceState(prevState => {return {...prevState, breadCrumbArr:singleBreadCrump.pathArray }})
        }

    },[singleBreadCrump.key])


     const connectionProfileFetcher = async () => {

                if(!profileData['connectionProfiles']) {
                    const connectionProfileData  = await GenericDassQuery(`/rest/connection-profiles`, { method: "GET" });
                    profileData['connectionProfiles'] = connectionProfileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }});
                }
                return profileData['connectionProfiles'];
        
            }

    useEffect(() => {
        if(constants.server_type === 'dmp') {
            connectionProfileFetcher()
        }
    }, [])


    useLayoutEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setDeviceState(prevState => { return {...prevState, loggedUser: AppContextObj.user}} )
            }
        }
    },[])

        const showDeleteProfileConfirmDialog = async ( row: IRowType ) => {
            const confirmDialogeSettings = {
                title:  profileLabels[profileType].confirm_dialog_delete_title,
                description: strings.formatString(profileLabels[profileType].confirm_dialog_delete_body, {ProfileName: row.profile_name}),
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    await GenericDassQuery(`/rest/${profilePath}/${row.profile_uuid}`, { method: "DELETE" });
                    refreshTable(true);
                    toast.success(profileLabels[profileType].success_delete_messsage);
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }



        const showUnlinkLinkConfirmDialog = async (row: IRowType) => {
            const confirmDialogeSettings = {
                title:  strings.UNLINK_LINKED_PROFILE_MODAL_TITLE,
                description: strings.formatString(strings.UNLINK_PROFILE_MESSAGE_MODAL_BODY, {ProfileName: row.profile_name}),
                actionLabel: strings.UNLINK_PROFILE_MODAL_CONFIRM_BUTTON,
            };

            if (await dialog(confirmDialogeSettings) === true) {

                try {
                    await GenericDassQuery(`/rest/${profilePath}/${row.profile_uuid}`, {
                        method: "PUT",
                        data: { copy_from_profile_uuid: row?.link_to_profile_uuid }
                    });
                    refreshTable();
                    toast.success(profileLabels[profileType].success_unlink_message);

                } catch (e) {
                    toast.error(e.message);
                }
            }
        }

        // Bulk Deleted Profile Function

        const batchProfileDelete = async ( selectedIds: IRowType[] ) => {

            const profileIds = selectedIds.map((row) => {
                return row['profile_uuid'];
            })
            const profilesName = selectedIds.map((row) => {
                return row['profile_name'];
            })
            if(profileIds && profileIds.length > 0) {
                const uiType = profileType === "connectionInstance" ? "connection" : `${profileType} profile`
                const confirmDialogeSettings = {
                    title:  translate(strings.BULK_DELETE_PROFILE_CONFIRMATION, {count: profileIds.length, uitype: uiType}) as string,
                    description: await dialogDescription(profilesName),
                    actionLabel: strings.DELETE,
                };
                if (await dialog(confirmDialogeSettings) === true) {
                    try {
                        Promise.all(profileIds.map((profileId) => {
                            return GenericDassQuery(`/rest/${profilePath}/` + profileId, { method: "DELETE" });
                        })).then((values) => {
                            refreshTable(true);
                            toast.success(`${profileIds.length} ${uiType} ` + strings.BULK_DELETE_PROFILE_SUCCESS);
                        }).catch((e) => {
                            toast.error(e.message);
                            refreshTable();
                        });
                    } catch (e) {
                        toast.error(e.message);
                    }       
                }
            }
        }




        const detailPageNav = ( navigate: NavigateFunction, tab: string, id: string, row: IRowType, profileType: ProfileType) => {
            const uiPath = profileType === "connectionInstance" ? "connections" : `profiles/${profileType}`
                navigate(`${getAppBase()}/${uiPath}/${id}/${tab}`, {state: {row, prevPageUrl:`${getAppBase()}/${uiPath}`}})
        }

        const addPageNav = ( navigate: NavigateFunction, tab: string, profileType: ProfileType) => {

            const uiPath = profileType === "connectionInstance" ? "connections" : `profiles/${profileType}`

            navigate(`${getAppBase()}/${uiPath}/${tab}`, {state: {row:{}, prevPageUrl:`${getAppBase()}/${uiPath}`}} )
        }

        const exportToJson = async (data: IRowType, fileName: string) => {
            
            try {
                const profileJSONResponse = await GenericDassQuery(`/rest/${profilePath}/${data.profile_uuid}`, {
                    method: "GET"
                });
                const row: IRowType = profileJSONResponse.data;
                delete row.profile_uuid;
                if (row.profile_image_url) {
                    try {
                        const profileImageResponse = await GenericDassQuery("/rest/filestorage/base64/" + row.profile_image_url.substring(10), {
                            method: "GET"
                        });
                        if (profileImageResponse.data) row.export_image_base64_json = profileImageResponse.data;
                    } catch (e) {
                        // Do Nothing
                    }   
                }
                const jsonRow = JSON.stringify(row);
                const blob = new Blob([jsonRow], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
                toast.success(profileLabels[profileType].success_export_message)
            } catch (e) {
                toast.error(e.message);
            }
          };

        const getActions = () => {

            const actions: ActionType[] =[
                {
                    type: "action",
                    text: strings.UNLINK_LINKED_PROFILE_ACTION,
                    render: (row, title) => actionIcon(row.profile_uuid, title, faLinkSlash.iconName),
                    action: (row: IRowType) => {
                        showUnlinkLinkConfirmDialog(row);
                    },
                    visible: (row: IRowType) => (row?.link_to_profile_uuid && row?.can_be_inspected ? true : false) && AppContextObj.user && !AppContextObj.user._readonly ,
                },

                {
                    type: "action",
                    text: (profileType === 'connectionInstance') ? strings.CONNECTION_DELETE_ACTION : strings.PROFILE_DELETE_ACTION,
                    render: (row, title) => actionIcon(row.profile_uuid, title, faTrashAlt.iconName),
                    action: (row: IRowType) => { showDeleteProfileConfirmDialog(row);},
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly },
                },

            ];
            

            if(constants.server_type === "dmp" && (profileType == "device" || profileType == "connection")) {
                actions.push( {
                    type: "action",
                    text: strings.EXPORT_DEVICE_BTN,
                    render: (row, title) => actionIcon(row.profile_uuid, title, faFileExport.iconName),
                    action: (row: IRowType) => {
                        exportToJson(row, row.profile_name)
                    },
                    visible: (row: IRowType) => (AppContextObj.user && AppContextObj.user[can_inspect_profile]) && !AppContextObj.user._readonly ,
                },)
            }
            const bulkActions: BulkActionType<IRowType>[] = [
                {
                    type: "action",
                    text:  (profileType === 'connectionInstance') ? strings.CONNECTION_DELETE_BULK_ACTION : strings.PROFILE_DELETE_BULK_ACTION,
                    render: (row, title) => actionIcon(row.profile_uuid, title, faTrashAlt.iconName),
                    action: (selectedIds) => batchProfileDelete(selectedIds),
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                }

            ]

            return {
                actions: actions,
                bulkActions:bulkActions
            };
        }

        const isComileFailed = (row: IRowType) => {
            if(row.eventHandlerDiagnostics?.compileFailed) {
                return strings.YES;
            } else {
                return strings.NO;
            }
        }

        const getDeviceTableOptions = () => {

            const { actions, bulkActions } = getActions();
            const columns: ColumnType<IRowType>[] = [
                {
                    key: 'bulk_action_checkbox',
                    type: "bulk_action_checkbox",
                    title: 'Bulk Action',
                    filterable: false,
                    cellWidth: 3,
                    newCellWidth: "30px",
                    customClass: 'sticky left-first',
                },
                {
                    key: "profile_uuid",
                    title: strings.TABLE_UUID,
                    type: "text",
                    render: (row) => ( isMobile ? row.profile_uuid : trustedFormatText(`[[fa-ellipsis]]${row.profile_uuid.slice(-6)}`, row.profile_uuid, null, null)),
                    filterField: 'search_uuid',
                    inputValidation: ID_INPUT_VALIDATION,
                    filterable: true,
                    render_tooltip: (row) => row.profile_uuid,
                    newCellWidth: (isMobile ? UUIDCOLWIDTH : '90px'),
                    customClass: 'font-monospace fa-80',
                    filterType: 'text',
                    dataAlign: 'center',
                    copyLink: true,
                    cellWidth: 35,
                }
            ];

            if (profileType === "device") {
                columns.push({
                    key: "profile_image_url",
                    title: strings.TABLE_IMAGE,
                    type: "icon_with_tooltip",
                    filterable: false,
                    render: (row) => <img style={{maxHeight: "30px", maxWidth:row.profile_image_url ? '70px' : '', height: "auto", width: "auto" } } src={row.profile_image_url?.startsWith("storageid:") 
                                                            ? "/storagecache/" + row.profile_image_url.substring(10)
                                                            : row.profile_image_url } />,
                    newCellWidth: '80px',
                    cellWidth: 6,
                    dataAlign: 'center'
                });
            }

            columns.push({
                key: "profile_name",
                type: "text",
                title: profileLabels[profileType].column_label,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                inputValidation: '',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                idField: 'profile_uuid',
                detailPageNav: function (row: IRowType) {
                    detailPageNav(navigate, TAB_PROFILE_EDIT_ACTION, row.profile_uuid, row, profileType) 
                },
                cellWidth: 28,
                newCellWidth: isMobile ? '250px' : '350px',
                sortable:true,
                sortKey:'sort_by_name',
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.profile_name

            })

            columns.push({
                key: "description",
                title: strings.TABLE_COMMENT,
                type: "icon_with_tooltip",
                filterable: true,
                inputValidation: '',
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                sortable:true,
                newCellWidth: '350px',
                cellWidth: 48,
                sortKey:'sort_by_description',
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.description
            });
            
            if ( (profileType === "channel" || profileType === "service" || profileType === "device" || profileType === "qos" || profileType === "relay") && constants.server_type !== "dmp") {
                columns.push({
                    key: "region",
                    title: strings.TABLE_REGION,
                    type: "text",
                    filterable: false,
                    cellWidth: 15,
                    newCellWidth: '90px',
                    detailLink: true,
                    copyLink: true,
                });
            }

            if(constants.server_type !== "dmp") {
                columns.push({
                    key: "link_to_profile_uuid",
                    title: strings.TABLE_LINK,
                    type: "text",
                    filterField: 'search_linked',
                    filterable: true,
                    filterType: 'select',
                    filterWidth: "65px",
                    newCellWidth: '90px',
                    dataAlign:'center',
                    filterParams: {
                        options: [
                            { label: strings.YES, value: "true" },
                            { label: strings.NO, value: "false" }
                        ]
                    },
                });
            }

           

           

            const renderConnectionProfile = (id) => {
                let display = "";
                try{
                    if(profileData['connectionProfiles'] && profileData['connectionProfiles'].length > 0){
                        const findIndex = profileData['connectionProfiles'].findIndex((res) => res.value == id);
                        if(findIndex != -1){
                            display = profileData['connectionProfiles'][findIndex].label
                        } 
                    }
                } catch(e){
                    console.log("e",e)
                }
        
                
                return display
                
            }

            if(constants.server_type === 'dmp' && profileType === 'connectionInstance') {
                columns.push({
                    key: "connection_profile_uuid",
                    title: strings.CONNECTION_PROFILE,
                    type: "text",
                    filterField: 'search_connection_profile_uuid',
                    filterable: true,
                    inputValidation: DEFAULT_INPUT_VALIDATION,
                    filterType: 'multiselect',
                    render: (row:IRowType) => renderConnectionProfile(row.connection_profile_uuid),
                    render_tooltip: (row:IRowType) => renderConnectionProfile(row.connection_profile_uuid),
                    newCellWidth: '300px',
                    extraClass: 'ow-datatable-overflow-ellipsis',
                    filterParams: {
                        optionFetcher: connectionProfileFetcher,
                        data: [],
                        mapper: x => x,
                    }
                });
            }

            if (constants.server_type === "dmp" && profileType === "connection") {
                columns.push({
                    key: "interface_type",
                    ignoreDBKey: true,
                    title: strings.TABLE_CONNECTION_INTERFACE_TYPE,
                    type: "text",
                    newCellWidth: "150px",
                    filterField: 'search_interface_type',
                    filterable: true,
                    filterType: 'select',
                    dataAlign:'center',
                    filterParams: {
                        options:[
                            {label:strings.INTERFACE_TYPE_HTTP, value: "HTTP"}
                            ,{label:strings.INTERFACE_TYPE_MQTT, value: "MQTT"}
                            ,{label:strings.INTERFACE_TYPE_TCP, value: "TCP"}
                            ,{label:strings.INTERFACE_TYPE_TR069, value: "TR069"}
                            ,{label:strings.INTERFACE_TYPE_EXTMQTT, value: "EXTMQTT"}
                        ]
                    },
                    render: (row: IRowType) => row.profileContent?.interfaceType || '',
                    cellWidth: 10,
                    sortable:true,
                    sortKey:'sort_by_interface_type',
                });
            }

            if (constants.server_type === "dmp" && profileType === "device") {
                columns.push({
                    key: "profileContent",
                    title: strings.TABLE_DEVICE_TYPE,
                    type: "text",
                    newCellWidth: "150px",
                    filterField: 'search_device_type',
                    filterable: true,
                    filterType: 'select',
                    dataAlign:'center',
                    filterParams: {
                        options:[
                            {label:strings.TABLE_DEVICE_TYPE_CUSTOM, value: "custom"}
                            ,{label:strings.TABLE_DEVICE_TYPE_TR069, value: "tr069"}
                            ,{label:strings.TABLE_DEVICE_TYPE_ORBIWAN, value: "orbiwan"}
                        ]
                    },
                    render: (row: IRowType) => {
                        // Show label
                        if(row.profileContent?.deviceType === "custom") {
                            return strings.TABLE_DEVICE_TYPE_CUSTOM;
                        } else if(row.profileContent?.deviceType === "tr069") {
                            return strings.TABLE_DEVICE_TYPE_TR069;
                        } else if(row.profileContent?.deviceType === "orbiwan") {
                            return strings.TABLE_DEVICE_TYPE_ORBIWAN;
                        } else {
                            return "";
                        }
                    },
                    cellWidth: 10,
                    sortable:false,
                    sortKey:'sort_by_device_type',
                });
            }

            if(constants.server_type === "dmp" && (profileType === "connection" || profileType === 'device')) {
                columns.push({
                    key: "eventHandlerDiagnostics",
                    title: strings.COMPILE_FAILED,
                    type: "boolean",
                    filterField: 'search_compile_failed',
                    filterable: true,
                    filterType: 'select',
                    newCellWidth: "120px",
                    filterWidth: "65px",
                    filterParams: {
                        options:[
                            {label:strings.YES, value: "true"}
                            ,{label:strings.NO, value: "false"}
                        ]
                    },
                    render: (row) => isComileFailed(row),
                },)
            }

            columns.push({
                key: 'action_button',
                type: "action_button",
                title: 'Actions',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "140px",
                customClass: 'sticky right',
            });


            if (isMobile) prioritizedTableColumns(['bulk_action_checkbox', 'profile_name'], columns)
            
        /*
        // FIXME Phase 2 Need to discuss for Phase 2
        
            columns.push({
                key: "profile_uuid",
                title: strings.TABLE_SHARED,
                type: "text",
                newCellWidth: colWidth.shared,
                filterField: 'search_shared',
                filterable: true,
                filterType: 'select',
                dataAlign:'center',
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (row: IRowType) => row.shared_with_all_users || row.is_shared ? strings.YES : strings.NO,
                cellWidth: 10
            });
        */

            const dataTableOption: DataTableOption<IRowType> = {
                allowBulkActions: true,
                columns,
                url: `/uiapi/rest/${profilePath}`,
                query_param: { all:true, limit: DEFAULT_RECORD_LIMIT, get_pages:true, stream: 'progress', show_region: true },
                serial_number: false,
                id_field: 'profile_uuid',
                oboe_path: 'pages.*',
                available_key: 'profile_uuid',
                emptyDataMsg: (profileType === 'connectionInstance' ? strings.NO_CONNECTION_AVAILABLE : strings.NO_PROFILE_AVAILABLE ),
                defaultSortField: 'sort_by_name',
                defaultSortOrder: 'asc',
                actions: actions,
                resizeWidth:['profile_name', 'description'],
                bulkActions: getVisibleActions(bulkActions)
            }

            return dataTableOption;
        }
        const refreshTable = (isRowDeleted = false) => {

            emptyProfileData();
            setDeviceState(prevState => {
                return {...prevState, refresh:!prevState.refresh, isRowDeleted: isRowDeleted}
            })

        }

        const getPageButtonType =  ( profileType: ProfileType ) => {

            const pageButtonTypesArr: { [key: string]: PageButtonType } = {
                device: {
                    title: strings.DEVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_DEVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly },
                    icon: faAdd
                },
                service:{
                    title: strings.SERVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_SERVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile]  && !AppContextObj.user._readonly},
                    icon: faAdd
                },
                connectivity:{
                    title: strings.CONNECTIVITY_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_CONNECTIVITY_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                },
                roaming:{
                    title: strings.ROAMING_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_ROAMING_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                },
                qos:{
                    title: strings.QOS_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_QOS_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                },
                channel:{
                    title: strings.CHANNEL_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_CHANNEL_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly },
                    icon: faAdd
                },
                relay:{
                    title: strings.RELAY_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_RELAY_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly },
                    icon: faAdd
                },
                connection:{
                    title: strings.CONNECTION_PROFILE,
                    action: () => addPageNav(navigate, TAB_ADD_CONNECTION_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                },
                connectionInstance:{
                    title: strings.CONNECTIONS,
                    action: () => addPageNav(navigate, TAB_ADD_CONNECTION, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                },

            }

            return pageButtonTypesArr[profileType];
        }

        const getPageButtons = () => {

            let currentPageButton = getPageButtonType(profileType);


            const pageButtons: PageButtonType[] = [];
            pageButtons.push(currentPageButton);
            if(constants.server_type !== "dmp") {
                pageButtons.push({
                    title: strings.NEW_LINKED_PROFILE,
                    //action: () => { AddNewLinkedProfile() },
                    action: () => addPageNav(navigate, TAB_CREATE_LINKED_PROFILE_ACTION, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_link_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                })
            }
            if (profileType === "device") {
                pageButtons.push({
                    title: strings.IMPORT_DEVICE_PROFILE,
                    action: () => addPageNav(navigate, TAB_IMPORT_DEVICE_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                });
            }
            if (profileType === "connection") {
                pageButtons.push({
                    title: strings.IMPORT_CONNECTION_PROFILE,
                    action: () => addPageNav(navigate, TAB_IMPORT_CONNECTION_PROFILE, profileType),
                    type: 'button_with_icon',
                    visible: () => { return AppContextObj.user && AppContextObj.user[can_create_profile] && !AppContextObj.user._readonly},
                    icon: faAdd
                });
            }
            pageButtons.push({
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            });

            return pageButtons;
        }


         if (constants.enable_profiles !== true) {
             return null;
         }else {

            return (<PageContent
                    name={profileType} 
                    id={id} 
                    tabname={tabname} 
                    actions={getActions()} 
                    breadCrumbArr={state.breadCrumbArr} 
                    pageButtons={getPageButtons()} 
                    countLabel={state.countLabel} 
                    dataTableOption={getDeviceTableOptions()} 
                    isRowDeleted={state.isRowDeleted}
                    refresh={state.refresh}>
                </PageContent>)

        }
}


export default Profiles;